import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SideDrawerSubmitButtons } from '../../components/Drawers/SideDrawerSubmitButtons';
import { DateInput } from '../../components/Inputs/DateInput';
import { TextInput } from '../../components/Inputs/TextInput';
import { ListBox, ListBoxValue } from '../../components/ListBox/ListBox';
import Toggle, { ToggleValue } from '../../components/Toggle/Toggle';
import { Employee, useAddEmployeeAllocationMutation } from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';

const allocVals: ListBoxValue<any>[] = [
  { id: 0, label: '100 %', value: 100 },
  { id: 1, label: '80 %', value: 80 },
  { id: 2, label: '60 %', value: 60 }
];

type WorkingTimeEditFormProps = {
  employee: Employee;
  onComplete: () => void;
};

export const WorkingTimeEditForm = ({ employee, onComplete }: WorkingTimeEditFormProps) => {
  const { dispatch } = useContext(SystemUiContext);
  const [inProgress, setInProgress] = useState<boolean>(false);

  // NOTE: The value ids correspond to both JS and PostgreSQL week day numbers
  const [partTimeWorkingDays, setPartTimeWorkingDays] = useState<ToggleValue[]>([
    { id: 1, label: 'Monday', selected: false },
    { id: 2, label: 'Tuesday', selected: false },
    { id: 3, label: 'Wednesday', selected: false },
    { id: 4, label: 'Thursday', selected: false },
    { id: 5, label: 'Friday', selected: false }
  ]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm<any>({
    shouldUnregister: true
  });

  const selectedAllocation = watch('allocation');

  useEffect(() => {
    if (selectedAllocation?.value === 80) {
      setPartTimeWorkingDays([
        { id: 1, label: 'Monday', selected: true },
        { id: 2, label: 'Tuesday', selected: true },
        { id: 3, label: 'Wednesday', selected: true },
        { id: 4, label: 'Thursday', selected: true },
        { id: 5, label: 'Friday', selected: false }
      ]);
    } else if (selectedAllocation?.value === 60) {
      setPartTimeWorkingDays([
        { id: 1, label: 'Monday', selected: true },
        { id: 2, label: 'Tuesday', selected: true },
        { id: 3, label: 'Wednesday', selected: true },
        { id: 4, label: 'Thursday', selected: false },
        { id: 5, label: 'Friday', selected: false }
      ]);
    }
  }, [selectedAllocation]);

  const [addAllocation] = useAddEmployeeAllocationMutation({
    onError: (err: any) => {
      setInProgress(false);
      console.log(err);
    },
    onCompleted: (_resp: any) => {
      onComplete();
      setInProgress(false);
      dispatch?.({
        type: SystemUiActions.SHOW_DRAWER,
        payload: {
          open: false,
          content: null
        }
      });
    }
  });

  const selectedWorkingDays = () => {
    if (selectedAllocation === undefined || selectedAllocation.value === 100) {
      return null;
    }
    return (
      <>
        <p className='text-gray-900 p-4'>
          Set the working days for the part time empoyee.{' '}
          <span className='text-black font-semibold'>If no days are selected</span> it is expected
          that the employee will work every day 6 hours.
        </p>
        <div className='space-y-5 p-4 flex-row'>
          {partTimeWorkingDays.map((wd) => (
            <Toggle
              key={wd.id}
              value={wd}
              onChange={(value) => {
                const next = [...partTimeWorkingDays.filter((v) => v.id !== value.id), value];
                next.sort((a, b) => a.id - b.id);
                setPartTimeWorkingDays(next);
              }}
            />
          ))}
        </div>
      </>
    );
  };

  const onSubmit = (data: any) => {
    let d = {
      employee_id: employee.id,
      start_date: data.day.startDate,
      allocation: data.allocation.value,
      comment: data.comment
    };
    if (selectedAllocation !== undefined && selectedAllocation.value !== 100) {
      // Database views expect non working days, so inverse the selection
      // Also the value ids correspond to both JS and PostgreSQL week day numbers
      const nonWorkingDays = partTimeWorkingDays.filter((v) => !v.selected).map((v) => v.id);
      d = Object.assign(d, { metadata: { agreed_non_working_day: nonWorkingDays } });
    }

    setInProgress(true);
    addAllocation({
      variables: {
        data: [d]
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-1 flex-col justify-between'>
      <div className='px-4 py-4 sm:px-6'>
        <DateInput<any>
          name='day'
          register={register}
          label={'Day'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: true }}
          errors={errors}
          control={control}
        />
        <TextInput<any>
          name='comment'
          register={register}
          label={'Comment'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: true }}
          errors={errors}
        />

        <ListBox
          values={allocVals}
          control={control}
          className='space-y-1 pt-1 pb-2'
          register={register}
          label='Working time'
          name='allocation'
          rules={{ required: true }}
          errors={errors}
        />

        {selectedWorkingDays()}
      </div>
      <SideDrawerSubmitButtons inProgress={inProgress} />
    </form>
  );
};
